<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useAssetSettings, useAssetSettingsLookups } from '@/composables/assets/assets';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Card from "@/components/Card/CardContainer.vue";
import CardContent from "@/components/Card/CardContent.vue";
import CardHeader from "@/components/Card/CardHeader.vue";
import CardHeaderItem from '@/components/Card/CardHeaderItem.vue';
import InputNumber from "@/components/InputNumber.vue";
import InputSelect from "@/components/InputSelect.vue";
import InputLabel from "@/components/input-label.vue";
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import ContentLoader from "@/components/content-loader.vue";
const props = defineProps<{ assetCode: number }>();

const { data: assetData, isLoading: asseetLoading} = useAssetSettings(props.assetCode);
const { data: lookups, isLoading: lookupsLoading } = useAssetSettingsLookups();
const assetDrawNumber = ref<number|null|undefined>();
const annualEscalation = ref<string>();
const contractLength = ref<string>();
const degradationType = ref<number>(1);
const isLoading = computed(() => asseetLoading.value || lookupsLoading.value)
const convertContractLength = (contractLengthInMonths: number) => {
    if (contractLengthInMonths === 12) return "1 year";

    const years = Math.floor(contractLengthInMonths / 12);
    const months = contractLengthInMonths % 12;
    const yearsPart = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
    const monthsPart = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';

    return [yearsPart, monthsPart].filter(Boolean).join(' ').trim();
};
watch(
    assetData,
    (newAssetData) => {
        if (newAssetData === undefined) {
            return
        }
        if (assetDrawNumber.value === undefined) {
            assetDrawNumber.value = newAssetData.contractTerms?.assetDrawerNumber;
        }
        if (annualEscalation.value === undefined) {
            annualEscalation.value = newAssetData.contractTerms?.annualEscalation?.name;
        }
        if (contractLength.value === undefined) {
            contractLength.value = convertContractLength(newAssetData.contractTerms?.contractLengthInMonths??0);
        }
        if (degradationType.value === undefined) {
            degradationType.value = newAssetData.contractTerms?.degradationType!;
        }
    }, { immediate: true });

</script>
<template>
    <Card class="contract-terms">
        <CardHeader>
            <CardHeaderItem>Contract Terms</CardHeaderItem>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`Access the Review Deal<br />
                tab to create new Annual <br />
                Escalation & Contract <br />
                Length dropdown options<br />
                (requires special access).`" />
        </CardHeader>
        <CardContent>
            <ContentLoader :loading="isLoading" variant="overlay">
                <GridContainer :columns="[122, '1fr']" :align="'center'" v-if="assetData">
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Asset Draw No.
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <InputNumber style="width: 62px; flex: none" placeholder="0" :value="assetData.contractTerms?.assetDrawerNumber"
                            name="contractTerms.assetDrawerNumber" error-variant="tooltip" />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Annual Escalation
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <span class="text-muted" v-if="!annualEscalation">Will display here</span>
                        <span v-else>{{ annualEscalation }}</span>
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">Contract Length</InputLabel>
                    </GridItem>
                    <GridItem>
                        <span v-if="!contractLength" class="text-muted">Will display here</span>
                        <span v-else>{{ contractLength }}</span>
                    </GridItem>
                    <GridItem>
                        <InputLabel class=" card-input-title">
                            Degradation Factor
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <InputSelect
                          placeholder="Please select"
                          item-text="name"
                          item-value="id"
                          :items="lookups?.degradationTypes || []"
                          v-model:selected="degradationType"
                          name="contractTerms.degradationType"
                          error-variant="tooltip" 
                          disabled/>
                    </GridItem>
                </GridContainer>
            </ContentLoader>
        </CardContent>
    </Card>
</template>
<style scoped lang="scss">
.contract-terms {
    width: 350px;
    min-height: 205px;

    .card-base-grid {
        display: grid;
        align-items: baseline;
        row-gap: 10px;
        grid-template-columns: 122px 1fr;
        column-gap: 20px;
        color: #6d6e71;
    }
}

.first-escalation-wrapper {
    display: grid;
    grid-template-columns: 101px 1fr;
    gap: 20px;
    height: 28px;
}

.medium-input {
    width: 115px;
}

.input-currency {
    :deep(.form-control) {
        text-align: right !important;
    }

    :deep(input) {
        border-left: none;
        text-align: right;
    }

    :deep(.input-group-text) {
        color: #d1d1d1;
        background-color: #fff !important;
    }

    :deep(.input-group) {
        width: unset !important;
    }
}

.input-percentage {
    :deep(.form-control) {
        text-align: left;
    }

    :deep(input) {
        border-right: none;
    }

    :deep(.input-group-text) {
        color: #d1d1d1;
        background-color: #fff !important;
    }
}

.card-input-title {
    font-size: 12px;
    font-weight: bold;
    color: #6d6e71;
}

.tooltip-color {
    color: #d1d1d1;
}
</style>
