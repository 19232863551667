<script setup lang="ts">
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import ModalBase from "@/components/modal/modal-container.vue";
import ModalTitle from "@/components/modal/modal-title.vue";
import ModalContent from "@/components/modal/modal-content.vue";
import ModalFooterConfirmation from "@/components/modal/modal-footer-confirmation.vue";
import { useSendInvoice } from "@/composables/billing/invoices";
import useNotifications from "@/composables/notifications";
import { computed } from "vue";

const props = defineProps<{
  invoiceId: number
  projectCode: number
  projectName: string
}>()
const emit = defineEmits<{ close: [] }>()

const { mutateAsync, isPending } = useSendInvoice()
const { addError, addSuccess } = useNotifications()
const invoiceDisplayName = computed(
  () => `${props.projectName} (${props.projectCode})`
)

async function sendInvoice() {
  try {
    await mutateAsync(props.invoiceId)

    addSuccess({
      title: 'Invoice Sent Successfully',
      allowHtml: true,
      message: `Invoice <b>${props.invoiceId}</b> for <b>${invoiceDisplayName.value}</b> has been successfully sent.`
    })

    emit('close')
  } catch (e: unknown) {
    addError({
      title: 'Invoice Not Sent',
      allowHtml: true,
      message: `Invoice <b>${props.invoiceId}</b> for <b>${invoiceDisplayName.value}</b> could not be sent due to an error that occurred. Please try again.`
    })
  }
}
</script>

<template>
  <ModalBase @close="$emit('close')">
    <ModalTitle>
      Send Email Confirmation
    </ModalTitle>
    <ModalContent :icon="faExclamationTriangle" icon-variant="warning">
      <p>
        Are you sure you would like to send Invoice <b>{{ invoiceId }}</b> for <b>{{ invoiceDisplayName }})</b>?
      </p>
    </ModalContent>
    <ModalFooterConfirmation
      @reject="$emit('close')"
      @confirm="sendInvoice()"
      :pending="isPending"
    />
  </ModalBase>
</template>

<style scoped lang="scss">

</style>