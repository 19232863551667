<script setup lang="ts">
import { useAssetSettings } from '@/composables/assets/assets';
import { defineProps } from 'vue';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import Card from "@/components/Card/CardContainer.vue";
import CardContent from "@/components/Card/CardContent.vue";
import CardHeader from "@/components/Card/CardHeader.vue";
import CardHeaderItem from '@/components/Card/CardHeaderItem.vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InputNumber from "@/components/InputNumber.vue";
import InputLabel from "@/components/input-label.vue";
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";

const props = defineProps<{ assetCode: number }>();
const { data: assetData, error: assetError, isLoading: asseetLoading } = useAssetSettings(props.assetCode);
</script>
<template>
    <Card class="utility-profile">
        <CardHeader>
            <CardHeaderItem>Utility Profile </CardHeaderItem>
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`Navigate to the<br />
                Utility Info tab to<br />
                change this info.`" />
        </CardHeader>
        <CardContent>
            <ContentLoader :loading="asseetLoading" variant="overlay">
                <HeroSplashError v-if="assetError" />
                <GridContainer v-else :columns="[122, '1fr']" align="center">
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Utility
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <span class="text-muted" v-if="!assetData?.utilityProvider?.name">Will Display here</span>
                        <span v-else>{{ assetData?.utilityProvider?.name }}</span>
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Tariff Structure
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <span class="text-muted" v-if="!assetData?.utilityProvider?.utilityTariffStructure?.name">Will display here</span>
                        <span v-else>{{ assetData?.utilityProvider?.utilityTariffStructure?.name }}</span>
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Blended Rate
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <GridContainer :columns="[122, '1fr']" align="center">
                            <GridItem>
                                <InputNumber placeholder="0,00" style="width: 115px; flex: none; text-align: right"
                                    class="medium-input input-currency" :value="assetData?.utilityProvider?.blendedTariff"
                                    name="utilityProvider.blendedTariff" error-variant="tooltip">
                                    <template #prefix>R</template>
                                </InputNumber>
                            </GridItem>
                            <GridItem>
                                <span class="text-muted">/ kWh</span>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </ContentLoader>
        </CardContent>
    </Card>
</template>
<style scoped lang="scss">
.utility-profile {
    width: 350px;
    min-height: 129px;

    .card-base-grid {
        display: grid;
        align-items: baseline;
        row-gap: 10px;
        grid-template-columns: 122px 1fr;
        column-gap: 20px;
        color: #6d6e71;
    }

    .tooltip-color {
        color: #d1d1d1;
    }
}
</style>
