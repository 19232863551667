<template>
  <h1 :class="$style['page-title']"><slot /></h1>
</template>

<style module lang="scss">
.page-title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
