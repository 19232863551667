<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import InvoiceDetails from '@/views/billing/invoicing/InvoiceDetails.vue'
import { useGetInvoice , useSaveCharges, useUpdateInvoiceConfig, useUpdateInvoiceMeterReadings } from '@/composables/billing/invoices'
import ContentLoader from '@/components/content-loader.vue'
import Button from "@/components/ButtonSecondary.vue"
import ButtonPrimary from "@/components/ButtonPrimary.vue"
import useLoader from '@/composables/loader'
import useNotifications from '@/composables/notifications'
import routeNames from "@/router/names"
import HeroSplashError from "@/components/hero-splash-error.vue"
import { faFloppyDisk, faXmark, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { InvoiceStatus } from '@/features/billing/constants'
import PageContent from '@/components/page-content.vue'

const route = useRoute()
const router = useRouter()
const invoiceId = parseInt(route.params.invoiceId.toString())
const assetCode = ref<number>(0)

const { data: invoiceData, isLoading: invoiceLoading, isError: invoiceDataHasError, isFetching } = useGetInvoice(invoiceId)

const { mutateAsync: updateInvoiceChargesAsync } = useSaveCharges(invoiceId)
const { mutateAsync: updateInvoiceConfigAsync } = useUpdateInvoiceConfig(invoiceId)
const { mutateAsync: updateMeterReadingsAsync } = useUpdateInvoiceMeterReadings(invoiceId)

const { wrap } = useLoader()
const { addSuccess, addError } = useNotifications()

type InvoiceCharges = {
    id: number,
    description: string,
    billingType: string,
    quantity: number,
    rate: number,
    total: number,
    isEditing: boolean
}

type InvoiceMeterReading = {
    id: number,
    deviceId: string,
    meterType: number,
    meterTypeName: string,
    serialNumber: string,
    startReading: number,
    endReading: number,
    reverseStartReading: number,
    reverseEndReading: number,
    readingDifference: number,
    hide: boolean
}

const invoice = computed(() => {
    return {
        id: invoiceData.value?.id ?? 0,
        invoiceStatusId: invoiceData.value?.invoiceStatusId ?? 0,
        invoiceNumber: invoiceData.value?.invoiceNumber ?? "",
        billingConfigurationId: invoiceData.value?.billingConfigurationId ?? 0,
        invoiceDate: invoiceData.value?.invoiceDate ?? new Date(),
        invoiceStartDate: invoiceData.value?.periodStartDate ?? new Date(),
        invoiceEndDate: invoiceData.value?.periodEndDate ?? new Date(),
        dueDate: invoiceData.value?.dueDate ?? new Date(),
        totalAmountExcludingTax: invoiceData.value?.totalAmountExcludingTax ?? 0,
        totalAmountIncludingTax: invoiceData.value?.totalAmountIncludingTax ?? 0,
        tax: invoiceData.value?.tax ?? 0,
        periodSavings: invoiceData.value?.invoiceSavings?.amount ?? 0,
        savingsToDate: invoiceData.value?.totalSavingsToDate ?? 0,
        gridConsumption: invoiceData.value?.totalGridConsumption ?? 0,
        totalEnergyConsumption: invoiceData.value?.totalEnergyConsumption ?? 0,
        totalEnergyExported: invoiceData.value?.totalEnergyExported ?? 0,
        isExportPower: invoiceData.value?.isExportPower ?? false
    }
})

const customer = computed(() => {
    return {
        name: invoiceData.value?.customer?.name ?? "",
        address: `${invoiceData.value?.customer?.addressLineOne}, ${invoiceData.value?.customer?.addressLineTwo}, ${invoiceData?.value?.customer?.suburb}
                        ${invoiceData.value?.customer?.city}, ${invoiceData.value?.customer?.region}`,
        vatNumber: invoiceData.value?.customer?.vatNumber ?? "",
        customerExternalIdentifier: invoiceData.value?.customer?.customerExternalIdentifier ?? ""
    }
})

const showMeterReadings = ref<boolean>(invoiceData.value?.showMeterReadings ?? false)
const showSavings = ref<boolean>(invoiceData.value?.showSavings ?? false)
const poNumber = ref<string>(invoiceData.value?.poNumber ?? "")
const invoiceStatus = ref<InvoiceStatus>(invoiceData.value?.invoiceStatusId ?? InvoiceStatus.Draft)

const owner = computed(() => {
    return {
        id: invoiceData.value?.contractFundEntity.id ?? 0,
        name: invoiceData.value?.contractFundEntity.name ?? "",
        address: invoiceData.value?.contractFundEntity.address ?? "",
        vatNumber: invoiceData.value?.contractFundEntity.vatNumber ?? "",
        bankDetails: {
            accountName: invoiceData.value?.contractFundEntity.accountName ?? "",
            bank: invoiceData.value?.contractFundEntity.bank ?? "",
            accountNumber: invoiceData.value?.contractFundEntity.accountNumber ?? "",
            branchCode: invoiceData.value?.contractFundEntity.branchCode ?? ""
        }
    }
})

var charges : InvoiceCharges [] = invoiceData.value?.invoiceCharges?.map(invoiceCharge => ({
        id: invoiceCharge.id ?? 0,
        description: invoiceCharge.description ?? "",
        billingType: invoiceCharge.chargeType.name ?? "",
        quantity: invoiceCharge.quantity ?? 0,
        rate: invoiceCharge.rate ?? 0,
        total: (invoiceCharge.rate ?? 0) * (invoiceCharge.quantity ?? 0),
        isEditing: false
    })) ?? []

var meterReadings : InvoiceMeterReading [] = invoiceData.value?.meterReadings?.map(meterReading => ({
        id: meterReading.id ?? 0,
        deviceId: meterReading.deviceId ?? "",
        meterType: meterReading.meterType.id,
        meterTypeName: meterReading.meterType.name,
        serialNumber: meterReading.serialNumber ?? "",
        startReading: meterReading.forwardActiveStartReading,
        endReading: meterReading.forwardActiveEndReading,
        reverseStartReading: meterReading.reverseActiveStartReading,
        reverseEndReading: meterReading.reverseActiveEndReading,
        readingDifference: meterReading.forwardActiveEndReading - meterReading.forwardActiveStartReading,
        hide: meterReading.hide
    })) ?? []

watch(invoiceData, (newInvoiceData) => {

    if(newInvoiceData == undefined)
        return
    assetCode.value=newInvoiceData.assetCode
    invoiceStatus.value = newInvoiceData.invoiceStatusId
    poNumber.value = newInvoiceData.poNumber ?? ""

    showMeterReadings.value = newInvoiceData.showMeterReadings
    showSavings.value = newInvoiceData.showSavings

    charges = newInvoiceData.invoiceCharges?.map(invoiceCharge => ({
        id: invoiceCharge.id ?? 0,
        description: invoiceCharge.description ?? "",
        billingType: invoiceCharge.chargeType.name ?? "",
        quantity: invoiceCharge.quantity ?? 0,
        rate: invoiceCharge.rate ?? 0,
        total: (invoiceCharge.rate ?? 0) * (invoiceCharge.quantity ?? 0),
        isEditing: false
    }))

    meterReadings = newInvoiceData.meterReadings?.map(meterReading => ({
        id: meterReading.id ?? 0,
        deviceId: meterReading.deviceId ?? "",
        meterType: meterReading.meterType.id,
        meterTypeName: meterReading.meterType.name,
        serialNumber: meterReading.serialNumber ?? "",
        startReading: meterReading.forwardActiveStartReading,
        endReading: meterReading.forwardActiveEndReading,
        reverseStartReading: meterReading.reverseActiveStartReading,
        reverseEndReading: meterReading.reverseActiveEndReading,
        readingDifference: meterReading.forwardActiveEndReading - meterReading.forwardActiveStartReading,
        hide: meterReading.hide
    }))
}, { immediate: true })

const handleChargesUpdate = (newCharges: InvoiceCharges []) => {
    charges = newCharges
}

const handleShowMeterReadingsUpdate = (newShowMeterReadings: boolean) => {
    showMeterReadings.value = newShowMeterReadings
}

const handleShowSavings = (newShowSavings: boolean) => {
    showSavings.value = newShowSavings
}

const handlePoNumberUpdate = (newPoNumber: string) => {
    poNumber.value = newPoNumber
}

const handleMeterReadingsUpdate = (newMeterReadings: InvoiceMeterReading []) => {
    meterReadings = newMeterReadings
}

const saveInvoice = () => wrap(
    async () => {

        await updateInvoiceChargesAsync(charges.map(charge => ({ id: charge.id, quantity: charge.quantity })))
        await updateInvoiceConfigAsync({invoiceId: invoiceId, showMeterReadings: showMeterReadings, showSavings: showSavings, poNumber: poNumber})

        var updatedMeterReadings = meterReadings.map(meterReading => ({
            id: meterReading.id,
            deviceId: meterReading.deviceId,
            forwardActiveStartReading: meterReading.startReading,
            forwardActiveEndReading: meterReading.endReading,
            reverseActiveStartReading: meterReading.reverseStartReading,
            reverseActiveEndReading: meterReading.reverseEndReading,
            hide: meterReading.hide
        }))

        await updateMeterReadingsAsync(updatedMeterReadings)

        navigateToInvoiceDashboard()
    }
)
.then(
    () => addSuccess({title: 'Invoice Updated'}),
    () => addError({title: 'Unable to update invoice', message: 'Please try again'})
)

const navigateToInvoiceDashboard = () => {
    router.push({name: routeNames.assetInvoicesDashboard})
}

</script>

<template>
  <PageContent>
    <ContentLoader :loading="invoiceLoading"></ContentLoader>
    <HeroSplashError v-if="invoiceDataHasError" />
    <InvoiceDetails v-if="!invoiceLoading && !invoiceDataHasError" :is-dummy-invoice="false" heading="Tax Invoice"
                    :invoice="invoice"
                    :customer="customer"
                    :owner="owner"
                    :charges="charges"
                    :showMeterReadings="showMeterReadings"
                    :showSavings="showSavings"
                    :poNumber="poNumber"
                    :meterReadings="meterReadings"
                    @update:charges="handleChargesUpdate"
                    @update:showMeterReading="handleShowMeterReadingsUpdate"
                    @update:showSavings="handleShowSavings"
                    @update:poNumber="handlePoNumberUpdate"
                    @update:MeterReadings="handleMeterReadingsUpdate">
    </InvoiceDetails>
    <div class="button-wrapper mt-4" v-if="!invoiceLoading">
      <ButtonPrimary :icon="faFloppyDisk" class="color-temp-white" @click="saveInvoice" :disabled="invoiceStatus == InvoiceStatus.Approved ||
                                                                                                invoiceStatus == InvoiceStatus.Sent ||
                                                                                                invoiceStatus == InvoiceStatus.Paid">Save</ButtonPrimary>
      <Button :icon="faXmark" class="one-h-width" @click="navigateToInvoiceDashboard">Cancel</Button>
      <span class="thank-you-text">Thank you!</span>
    </div>

  </PageContent>
</template>

<style lang='scss'>
  .button-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
    .thank-you-text {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
</style>
