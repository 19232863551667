<script setup lang="ts">
import Button from '@/components/ButtonPrimary.vue'
import { useRoute } from 'vue-router'
import { faSignInAlt } from '@fortawesome/pro-regular-svg-icons'
import { ref } from 'vue'
import InputPassword from '@/components/InputPassword.vue'
import InputText from '@/components/InputText.vue'
import useAuthentication from '@/composables/users/authentication'
import { useForm } from "vee-validate";
import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import routeNames from "@/router/names";
import { makeCoreUrl, navigateTo } from "@/helpers/routing";

defineOptions({ name: 'View-AuthLogin' })

const username = ref('')
const password = ref('')
const authenticationFailed = ref(false)
const route = useRoute()
const { mutateAsync: authenticate } = useAuthentication()
const isAuthenticating = ref(false)
const { handleSubmit } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      username: z.string().email(),
      password: z.string().min(6),
    })
  )
})

const beginSignIn = handleSubmit(({ username, password }) => {
  isAuthenticating.value = true
  authenticationFailed.value = false

  authenticate({ username, password }).then(
    () => {
      const redirectUrl = route.query['r']

      if (typeof redirectUrl === 'string') {
        window.location.href = redirectUrl
      } else {
        navigateTo(routeNames.projectsDashboard)
      }
    }
  ).catch(
    () => {
      authenticationFailed.value = true
      isAuthenticating.value = false
    }
  )
})
</script>

<template>
  <h3 class="mt-4 mb-4">Please log in to continue with your awesome work...</h3>

  <div style="color: red" v-if="authenticationFailed" class="mb-3">Authentication failed.</div>

  <div :class="$style['wrapper']" class="ms-auto me-auto">
    <form @submit="beginSignIn">
      <InputText type="email" name="username" placeholder="Email address" v-model:value="username" />
      <InputPassword
        class="mt-4"
        name="password"
        placeholder="Password (6+ characters)"
        v-model:value="password" />
      <Button
        class="mt-4 ps-5 pe-5"
        :icon="faSignInAlt"
        type="submit"
        :preset="isAuthenticating ? 'loading' : undefined"
        :disabled="isAuthenticating"
        >Login
      </Button>
    </form>
  </div>

  <div class="mt-4">
    <a :href="makeCoreUrl(['Home', 'GetForgotPassword'])">Forgot password?</a>
  </div>
</template>

<style module lang="scss">
.wrapper {
  width: 276px;
}
</style>
