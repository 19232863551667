<script setup lang="ts" generic="T extends object|string|number">
import { objectHasKey } from "@/helpers";

const props = withDefaults(
  defineProps<{
    options: T[]
    optionText?: string
    selectedIndex?: number
    size?: 'small' | 'normal' | 'large'
    disabled?: boolean
  }>(),
  { size: 'small', disabled: false }
)

defineEmits<{
  'update:selected-index': [number]
}>()

function getTextValue(option: T) {
  if (typeof option === 'string' || typeof option === 'number') {
    return option
  }

  if (!props.optionText) {
    console.warn('[optionText] is required when non-scalar values are passed as options.')

    return ''
  }

  if (!objectHasKey(option, props.optionText)) {
    console.warn(
      '[optionText] must be a property that exists on the option',
      JSON.stringify({ option, optionText: props.optionText }, null, 2)
    )

    return ''
  }

  return option[props.optionText]
}
</script>

<template>
  <div class="btn-group" :class="{ 'btn-group-sm': size === 'small', 'btn-group-lg': size === 'large' }">
    <button
      v-for="(option, index) in options"
      :key="index"
      :disabled="disabled"
      @click="$emit('update:selected-index', index)"
      :class="[$style.btn, { [$style.active]: selectedIndex === index }]">{{
        getTextValue(option)
    }}</button>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.btn {
  color: $body-color;
  padding: 3px 10px;
  background: $white;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: $input-border-color;

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.active {
    border-width: 1px;
  }

  &:hover:not(.active) {
    background: #f9f9f9;
  }
}

.active + .btn {
  border-left-width: 0;
}

.active {
  background: $yellow;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}
</style>
