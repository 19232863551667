<script setup lang="ts">
import routeNames from "@/router/names";
import logoUrl from "@/assets/top-nav/unifii-logo.webp";
import {
  faCog,
  faHandHoldingBox,
  faIdCardAlt, faInventory, faListAlt, faMoneyCheck, faSignOutAlt,
  faStoreAlt,
  faUserCircle,
  faUsers, faUtilityPole
} from "@fortawesome/pro-regular-svg-icons";
import DropdownItem from "@/components/DropdownItem.vue";
import DropdownDivider from "@/components/DropdownDivider.vue";
import useAuthenticatedUserDetails, { AccessArea, AccessRight } from "@/composables/users/authenticated-user-details";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { makeCoreUrl } from "@/helpers/routing";
import useOrganisationDetails from "@/composables/organisations/organisation-details";

const { hasPermission, data: authenticatedUser } = useAuthenticatedUserDetails()
const { data: organisation } = useOrganisationDetails(() => authenticatedUser.value?.organisationId)
</script>

<template>
  <nav class="navbar navbar-expand" :class="$style['nav-primary']">
    <div class="container-fluid">
      <RouterLink :to="{ name: routeNames.projectsDashboard }" class="navbar-brand">
        <img :src="logoUrl" height="27" alt="Unifii logo" />
      </RouterLink>

      <ul class="navbar-nav align-items-center" v-if="authenticatedUser && organisation">

        <li class="nav-item" v-if="hasPermission(AccessArea.SystemAdministrator)">
          <a :href="makeCoreUrl('Admin')" class="nav-link">
            <FontAwesomeIcon :icon="faCog" />
          </a>
        </li>

        <li class="nav-item dropdown">
          <a href="javascript:void(0)" class="nav-link" data-bs-toggle="dropdown">
            <FontAwesomeIcon :icon="faUserCircle" />
          </a>
          <ul class="dropdown-menu shadow-sm dropdown-menu-end">
            <DropdownItem
              :icon="faIdCardAlt"
              :href="makeCoreUrl('MyProfile/GetProfileSettings')">
              My Profile
            </DropdownItem>

            <DropdownItem
              :icon="faStoreAlt"
              :href="makeCoreUrl(`Admin/GetOrganizationDetails?encryptedId=${encodeURIComponent(organisation.encryptedId)}`)"
              v-if="hasPermission(AccessArea.Administrator)">
              Organisation Details
            </DropdownItem>

            <DropdownItem
              :icon="faUsers"
              :href="makeCoreUrl(`Manage/GetManageUsers?encryptedId=${encodeURIComponent(organisation.encryptedId)}`)"
              v-if="hasPermission(AccessArea.Administrator)">
              Manage Users
            </DropdownItem>

            <DropdownItem
              :icon="faHandHoldingBox"
              :href="makeCoreUrl(`Supplier/Index?encryptedId=${encodeURIComponent(organisation.encryptedId)}`)"
              v-if="hasPermission(AccessArea.Product, AccessRight.Executive)">
              Suppliers
            </DropdownItem>

            <DropdownItem
              :icon="faInventory"
              :href="makeCoreUrl(`Product/Index?encryptedId=${encodeURIComponent(organisation.encryptedId)}`)"
              v-if="hasPermission(AccessArea.Product, AccessRight.Executive)">
              Products
            </DropdownItem>

            <DropdownItem
              :icon="faUtilityPole"
              :href="makeCoreUrl(`UtilityAndPower?encryptedId=${encodeURIComponent(organisation.encryptedId)}`)"
              v-if="hasPermission(AccessArea.Administrator)">
              Utility & Power
            </DropdownItem>

            <DropdownDivider />

            <DropdownItem
              :icon="faMoneyCheck"
              :href="makeCoreUrl('Payment/GetGenerateBulkPaymentView')"
              v-if="hasPermission(AccessArea.BulkPayment, AccessRight.Basic)">
              Generate Bulk Payments
            </DropdownItem>

            <DropdownItem
              :icon="faListAlt"
              :href="makeCoreUrl('TermsAndConditions/UserTermsAndConditionsView')">
              Terms of use
            </DropdownItem>

            <DropdownItem :icon="faSignOutAlt" :href="makeCoreUrl('Account/LogOff')">
              Log out
            </DropdownItem>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style module lang="scss">
.nav-primary {
  padding: 0;
  min-height: 50px;
  background: #103a48;

  :global {
    .navbar-brand {
      line-height: 0;
    }

    .navbar-nav {
      > .nav-item > .nav-link {
        font-size: 21px;
        color: #fff;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>