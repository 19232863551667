<script setup lang="ts">
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import InputSelect from "@/components/InputSelect.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

const $props = withDefaults(
  defineProps<{
    totalRecords: number
    page: number
    pageSize: number
    availablePageSizes?: number[]
    align?: 'start' | 'end'
  }>(),
  {
    availablePageSizes: () => [10, 25, 50],
    align: 'end'
  }
)

defineEmits<{
  'update:page': [number],
  'update:pageSize': [number],
}>()

const lastRecord = computed(
  () => Math.min($props.page * $props.pageSize, $props.totalRecords)
)

const lastPage = computed(
  () => Math.ceil($props.totalRecords / $props.pageSize)
)

const firstRecord = computed(
  () => ($props.page - 1) * $props.pageSize + 1
)
</script>

<template>
  <div class="mt-4 d-flex" :class="`justify-content-${'default' in $slots ? 'between' : align}`">
    <div v-if="'default' in $slots && align === 'end'"><slot /></div>

    <div>
      Rows per page
      <InputSelect
        inline
        :selected="pageSize"
        @update:selected="$emit('update:pageSize', $event)"
        :items="
          Array.from(new Set(availablePageSizes.concat(pageSize)))
            .sort((a, b) => a - b)
            .map(v => ({ v }))
        "
        item-text="v"
        item-value="v"
        class="ms-2 me-4" />
      <span>
        {{ firstRecord }} - {{ lastRecord }}
        of {{ totalRecords }}
      </span>
      <span :class="$style['prev']" @click="page > 1 ? $emit('update:page', page - 1) : void(0)">
        <FontAwesomeIcon :icon="faChevronLeft" />
      </span>
      <span :class="$style['next']" @click="page < lastPage ? $emit('update:page', page + 1) : void(0)">
        <FontAwesomeIcon :icon="faChevronRight" />
      </span>
    </div>

    <div v-if="'default' in $slots && align === 'start'"><slot /></div>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.prev, .next {
  cursor: pointer;
  min-width: 2rem;
  padding: 0.5rem;
  text-align: center;
  border-radius: 50%;
  margin-left: 20px;
  display: inline-block;
  color: $body-color;

  &:hover {
    background-color: #f6f6f6;
    color: darken($body-color, 25%);
  }
}
</style>