<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
  blur: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits<{
  (e: "input", v: boolean): void;
  (e: "opened"): void;
  (e: "closed"): void;
}>();

const el = ref();

onMounted(() => {
  el.value.addEventListener("close", () => {
    emit("input", false);
    emit("closed");
  });
});

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue) {
      el.value.showModal();
      console.log(oldValue);
      emit("input", true);
      emit("opened");
    } else {
      el.value.close();
    }
  }
);

defineExpose({
  close: () => {
    el.value.close();
  },
});
</script>

<template>
  <dialog class="native-modal" :class="{ 'native-modal--blur': props.blur }" ref="el">
    <slot />
  </dialog>
</template>

<style scoped lang="scss">
.native-modal {
  background: #fff;
  border: 1px solid #ddd;
  position: fixed;
  z-index: 9999;
  width: 500px;
  box-shadow: 0 5px 3px #00000040;
  padding: 25px 25px 30px 25px;
  border-radius: 4px;
  color: var(--u-color-primary-text);

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
    opacity: 1; // Something is setting this to 0.5, so we need to set it back.
  }

  &.native-modal--blur::backdrop {
    backdrop-filter: blur(1px);
  }
}
</style>
