import names from "@/router/names";
import LayoutAuth from "@/views/layouts/auth.vue";
import AuthLogin from "@/views/auth/login.vue";
import TermsOfUse from "@/views/TermsOfUse.vue";
import type { RouteLocation, RouteLocationNormalizedGeneric, RouteRecordRaw } from 'vue-router'
import ProjectsDashboardListProjects from "@/views/projects/dashboard/list-projects.vue";
import AssetSetup from "@/views/assetSetup/setup.vue";
import BillingInvoicingDashboard from "@/views/billing/invoicing/dashboard.vue";
import TaxInvoiceTemplate from '@/views/billing/invoicing/taxInvoiceTemplate.vue'
import TaxInvoice from "@/views/billing/invoicing/taxInvoice.vue"
import AssetsLayout from '@/views/layouts/assets-layout.vue'
import AssetMetersDashboard from '@/views/assets/meters/asset-meters-dashboard.vue'

const paramAsInt = (to: RouteLocationNormalizedGeneric, paramName: string) => (
  { ...to.params, [paramName]: Number(to.params[paramName]) }
)

export default [
  { path: '/',
    redirect: { name: names.projectsDashboard }
  },
  { path: '/billing/invoices',
    redirect: { name: names.assetInvoicesDashboard }
  },
  { path: '/assets/:assetCode/manage-invoice',
    redirect: to => ({ name: names.invoiceSetup, params: to.params })
  },
  { path: '/assets/billing/invoice/:invoiceId',
    redirect: to => ({ name: names.invoice, params: to.params })
  },

  // General
  { path: '/terms-and-conditions',
    component: TermsOfUse,
    name: names.termsAndConditions,
    meta: { requiresAuth: false }
  },
  { path: '/login',
    component: LayoutAuth,
    meta: { requiresAuth: false, pageTitle: 'Login' },
    children: [
      { path: '', component: AuthLogin, name: names.login },
    ]
  },

  // Projects
  { path: '/projects',
    component: ProjectsDashboardListProjects,
    name: names.projectsDashboard
  },

  // Assets
  { path: '/assets/:assetCode',
    component: AssetsLayout,
    children: [
      { path: 'settings', name: names.assetSettings, component: AssetSetup, props: to => paramAsInt(to, 'assetCode') },
      { path: 'invoicing/template', name: names.invoiceSetup, component: TaxInvoiceTemplate, props: to => paramAsInt(to, 'assetCode') },
      { path: 'meters', name: names.assetMetersDashboard, component: AssetMetersDashboard, props: to => paramAsInt(to, 'assetCode') },
    ]
  },

  // Invoices
  { path: '/invoices',
    component: BillingInvoicingDashboard,
    name: names.assetInvoicesDashboard,
    meta: { pageTitle: 'Asset Invoices' }
  },
  { path: '/invoices/:invoiceId',
    name: names.invoice,
    component: TaxInvoice,
  },
] as RouteRecordRaw[]
