<script setup lang="ts">
import ModalBase from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import ModalFooter from "@/components/modal/modal-footer.vue";
import ButtonSecondary from "@/components/ButtonSecondary.vue";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { ref } from "vue";
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import InputLabel from "@/components/input-label.vue";
import InputSelect from "@/components/InputSelect.vue";
import InputTextarea from "@/components/InputTextarea.vue";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "zod";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import { useProjectClosure } from "@/composables/projects/close-project";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import useNotifications from "@/composables/notifications";

const $emit = defineEmits<{ close: [] }>()
const $props = defineProps<{ projectId: number, projectName: string }>()

const { addSuccess, addError } = useNotifications()

const { handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      reasonId: z.number(),
      text: z.string().min(1).optional()
    }).refine(
      values => {
        // If ID == 4, then a reason is required.
        return !(values.reasonId === 4 && !values.text)
      }
    )
  )
})

const selectedReason = ref<number>()

const {
  closureReasons: {
    data: closureReasons,
    isLoading: isLoadingClosureReasons,
    isError: isErrorClosureReasons
  },
  closeProject: {
    mutateAsync: closeProjectAction,
    isPending: isClosingProject
  }
} = useProjectClosure({ projectId: $props.projectId })

const closeProject = handleSubmit(
  async ({ reasonId, text }) => {
    try {
      await closeProjectAction({ reasonId, text })

      addSuccess({ title: 'Project closed successfully.' })

      $emit('close')
    } catch (e: unknown) {
      addError({
        title: 'Unable to close project.',
        message: `Something went wrong trying to close this project. Please try again.`
      })
    }
  }
)

</script>

<template>
  <ModalBase :width="500" @close="$emit('close')">
    <ModalTitle>Close Project</ModalTitle>
    <ModalContent>
      <HeroSplashError v-if="isErrorClosureReasons" />
      <ContentLoader v-else :loading="isLoadingClosureReasons">
        <GridContainer variant="inputs">
          <GridItem>
            <InputLabel class="card-input-title">Reason</InputLabel>
          </GridItem>
          <GridItem>
            <InputSelect name="reasonId" :items="closureReasons || []" item-text="name" item-value="id"
              v-model:selected="selectedReason" />
          </GridItem>

          <template v-if="selectedReason === 4">
            <GridItem />
            <GridItem>
              <InputTextarea name="text" required />
            </GridItem>
          </template>
        </GridContainer>

      </ContentLoader>
    </ModalContent>
    <ModalFooter class="text-end">
      <ButtonSecondary :icon="faTimes" @click="$emit('close')">Cancel</ButtonSecondary>
      <ButtonPrimary class="ms-2" :icon="faCheck" :disabled="!meta.valid"
        :preset="isClosingProject ? 'loading' : undefined" @click="closeProject">Continue</ButtonPrimary>
    </ModalFooter>
  </ModalBase>
</template>
