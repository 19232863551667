<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    columns?: (string|number)[]
    columnGap?: string|number
    rowGap?: string|number

    variant?: 'inputs'
    rows?: string
    align?: 'start' | 'center' | 'end'
    // cols?: (string|number)[] | string | 'preset:inputs'
  }>(),
  {
    align: ({ variant }) => variant === 'inputs' ? 'center' : 'start',
    columns: ({ variant }) => {
      if (variant === 'inputs') {
        return [88, '1fr']
      }

      console.warn('{GridContainer} [columns] prop is required when no [preset] prop is provided.')

      return []
    }
  }
)

const toMeasurementString = (value: number|string) => (
  typeof value === 'number' ? `${value}px` : value
)

const gridStyles = computed(
  () => {
    const gridStyles: Record<string, string> = {
      '--grid-template-columns': props.columns.map(toMeasurementString).join(' '),
    }

    if (props.rowGap !== undefined) {
      gridStyles['--row-gap'] = toMeasurementString(props.rowGap)
    }

    if (props.columnGap !== undefined) {
      gridStyles['--column-gap'] = toMeasurementString(props.columnGap)
    }

    if (props.rows !== undefined) {
      gridStyles['--grid-template-rows'] = props.rows
    }

    return gridStyles
  }
)
</script>

<template>
  <div :class="[`align-items-${align}`, $style['grid-container']]" :style="gridStyles">
    <slot />
  </div>
</template>

<style module lang="scss">
.grid-container {
  display: grid;
  align-items: var(--align-items, start);
  column-gap: var(--column-gap, 15px);
  row-gap: var(--row-gap, 10px);
  grid-template-columns: var(--grid-template-columns);
  grid-template-rows: var(--grid-template-rows);
}
</style>