<script setup lang="ts">
import { ref, watch, computed, h, withDirectives } from 'vue';
import { useAssetSettings, useAssetSettingsLookups, useAssetCustomers } from '@/composables/assets/assets';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import Card from "@/components/Card/CardContainer.vue";
import CardContent from "@/components/Card/CardContent.vue";
import CardHeader from "@/components/Card/CardHeader.vue";
import CardHeaderItem from "@/components/Card/CardHeaderItem.vue";
import InputSelect from "@/components/InputSelect.vue";
import InputLabel from "@/components/input-label.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import GridContainer from "@/components/grid-container.vue";
import GridItem from "@/components/grid-item.vue";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import tooltip from '@/directives/tooltip'
import { createLogger } from '@/helpers/logging'

const props = defineProps<{ assetCode: number }>();
const logger = createLogger('views/assetSetup/InvoiceDetailsCard')

const contractFundEntityId = ref<number>();
const selectedCustomerId = ref<string | null>();

const { data: assetData, error: assetError, isLoading: assetLoading } = useAssetSettings(props.assetCode);
const { data: lookups, error: lookupsError, isLoading: lookupsLoading } = useAssetSettingsLookups();
const { data: customers, isLoading: customersLoading } = useAssetCustomers(contractFundEntityId);

const fundEntityCustomer = computed(()=> customers.value?.find(x=>x.customerExternalIdentifier ===selectedCustomerId.value))
const isLoading = computed(() => assetLoading.value || lookupsLoading.value);

watch(assetData, (newAssetData) => {
    if (!newAssetData) return;
    if (!selectedCustomerId.value) {
        selectedCustomerId.value = newAssetData.invoiceDetails.customer?.customerExternalIdentifier;
    }
    if (!contractFundEntityId.value) {
        contractFundEntityId.value = newAssetData.invoiceDetails.contractFundEntity?.id;
    }
}, { immediate: true });

function CustomerDetailOrMissingMessage(props: { detail?: string|null }) {
  if (!selectedCustomerId.value) {
    logger.debug('Showing [Will display here] for customer detail.')

    return h('span', { class: 'text-muted' }, ['Will display here'])
  }

  if (!props.detail) {
    logger.debug('Showing [Update Needed] for customer detail.', {
      selectedCustomerId: selectedCustomerId.value,
      providedDetail: props.detail,
      tooltipDirective: tooltip,
    })

    return withDirectives(
      h('span', { class: 'text-danger' }, ['Update Needed']),
      [[tooltip, { placement: 'bottom', text: `Missing data detected.<br>Please update the<br>information in Xero.` }]]
    )
  }

  logger.debug('Showing customer detail.', {
    selectedCustomerId: selectedCustomerId.value,
    providedDetail: props.detail,
  })

  return h('span', [props.detail])
}
const sortedCustomers = computed(() => {
    if (!customers.value) return [];

    return customers.value.slice().sort((a, b) => {
        if (!a.name || !b.name) return 0;
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA.localeCompare(textB);
    });
});

</script>
<template>
    <Card class="invoice-details-card">
        <CardHeader>
            <CardHeaderItem>Invoice Details</CardHeaderItem>
        </CardHeader>
        <CardContent>
            <ContentLoader :loading="isLoading" variant="overlay">
                <HeroSplashError v-if="assetError || lookupsError" />
                <GridContainer v-else :columns="[80, '1fr']" align="center">
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Owner
                        </InputLabel>
                        <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`The fund that<br />owns the assets.`" />
                    </GridItem>
                    <GridItem v-if="lookups && assetData">

                        <InputSelect
                          :placeholder="isLoading ? 'Loading...' : undefined"
                          item-text="name"
                          item-value="id"
                          :items="lookups.contractFundEntities.slice().sort((a, b) =>{
                                                                    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
                                                                    })"
                          required
                          v-model:selected="contractFundEntityId"
                          name="invoiceDetails.contractFundEntity.id"
                          error-variant="tooltip"
                        />
                    </GridItem>
                    <GridItem :span="2">
                        <span class="sub-title">Customer Details</span>
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Customer
                        </InputLabel>
                        <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle" v-tooltip="`The company you’re doing the<br />transaction with, aka. the legal<br />entity that signed the contract.`" />
                    </GridItem>
                    <GridItem v-if="lookups && assetData">
                        <InputSelect
                          :placeholder="customersLoading ? 'Loading...' : undefined"
                          item-value="customerExternalIdentifier"
                          item-text="name"
                          v-model:selected="selectedCustomerId"
                          :items="sortedCustomers|| []"
                          required
                          name="invoiceDetails.customer.customerExternalIdentifier"
                          error-variant="tooltip"
                        />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Street
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                        <CustomerDetailOrMissingMessage :detail="fundEntityCustomer?.addressLineOne" />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Suburb
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                      <CustomerDetailOrMissingMessage :detail="fundEntityCustomer?.suburb" />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            City
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                      <CustomerDetailOrMissingMessage :detail="fundEntityCustomer?.city" />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Country
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                      <CustomerDetailOrMissingMessage :detail="fundEntityCustomer?.country" />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            Region
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                      <CustomerDetailOrMissingMessage :detail="fundEntityCustomer?.region" />
                    </GridItem>
                    <GridItem>
                        <InputLabel class="card-input-title">
                            VAT No.
                        </InputLabel>
                    </GridItem>
                    <GridItem>
                      <CustomerDetailOrMissingMessage :detail="fundEntityCustomer?.vatNumber" />
                    </GridItem>
                </GridContainer>
            </ContentLoader>
        </CardContent>
    </Card>
</template>
<style scoped lang="scss">
.invoice-details-card {
    width: 407px;
    min-height: 269px;

    .card-base-grid {
        display: grid;
        align-items: baseline;
        row-gap: 10px;
        column-gap: 20px;
        color: #6d6e71;
    }

    .sub-title {
        font-size: 13px;
        font-weight: bold;
        color: #6d6e71;
        text-transform: uppercase;
    }
}

.tooltip-color {
    color: #d1d1d1;
}
</style>
