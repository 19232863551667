<script setup lang="ts">
import type { ZodSchema } from 'zod'
import { toRef, useAttrs } from "vue";
import { withoutKeys } from '@/helpers'
import { useUnifiiField } from "@/composables/inputs";

// Prevent attribute inheritance.
defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<
    Partial<{
      name: string
      value: string
      rules: ZodSchema
      fixed: boolean
      inline: boolean
      required: boolean
      errorVariant?: 'inline' | 'tooltip'
    }>
  >(),
  { name: '', value: '', errorVariant: 'inline' }
)

const $attrs = useAttrs()
const $emit = defineEmits<{ 'update:value': [string] }>()
const emitValue = (value: string) => $emit('update:value', value)

const {
  errorMessage,
  value: currentValue,
  handleBlur,
  handleChange,
  handleInput
} = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: props.value,
  emitter: (value: string) => $emit('update:value', value)
})
</script>

<template>
  <div :class="{ 'd-inline-block': inline, 'd-block': !inline }">
    <div
      class="position-relative"
      :class="[
        {
          'has-validation': errorMessage,
          [$style['input-textarea-fixed']]: fixed,
          [$style['required']]: required,
          'input-group': 'prefix' in $slots || 'suffix' in $slots,
        },
        $style['input-textarea'],
        $attrs.class
      ]">
      <span class="input-group-text" v-if="'prefix' in $slots"><slot name="prefix" /></span>
      <textarea
        v-bind="withoutKeys($attrs, ['class'])"
        v-tooltip="errorVariant === 'tooltip' ? { text: errorMessage, variant: 'error' } : undefined"
        :name="name"
        :value="currentValue"
        class="form-control"
        :class="{ 'is-invalid': errorMessage }"
        @input="handleInput"
        @change="handleChange"
        @blur="handleBlur"
      />
      <div class="invalid-tooltip" v-if="errorVariant === 'inline' && errorMessage">{{ errorMessage }}</div>
      <span class="input-group-text" v-if="'suffix' in $slots"><slot name="suffix" /></span>
    </div>
  </div>
</template>

<style module lang="scss">
@use "@/assets/styles/mixins-inputs";
@use "@/assets/mixins/inputs";

@import "@/assets/variables";
@import "@/assets/bootstrap-variables";

.input-textarea {
  textarea {
    line-height: 1.5;
  }

  @include mixins-inputs.fix-error-tooltips-for-inputs;
  @include inputs.apply-prefix-and-suffix-styles;
}

.input-textarea-fixed {
  textarea {
    resize: none;
  }
}

.required {
    @include inputs.apply-required-indicator-styles;
}
</style>
