import { type MaybeRefOrGetter, toValue } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { deleteLinkedMeter, getLinkedMeters, getUnlinkedMeters, linkMeter } from '@/features/assets/meters'

export const useLinkedMeters = (assetCode: MaybeRefOrGetter<number|undefined>) => useQuery({
  queryKey: ['assets', 'meters', 'linked', assetCode],
  enabled() {
    const reducedValue = toValue(assetCode)

    return reducedValue !== undefined && reducedValue > 0
  },
  queryFn: () => getLinkedMeters(toValue(assetCode)!)
})

export const useUnlinkedMeters = (assetCode: MaybeRefOrGetter<number>) => useQuery({
  queryKey: ['assets', 'meters', 'unlinked', assetCode],
  queryFn: () => getUnlinkedMeters(toValue(assetCode))
})

export function useDeleteMeter() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (meterId: MaybeRefOrGetter<number>) => deleteLinkedMeter(toValue(meterId)),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ['assets', 'meters'] })
    },
  })
}

export function useLinkMeter(assetCode: MaybeRefOrGetter<number>) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (meter: Parameters<typeof linkMeter>[1]) => linkMeter(toValue(assetCode), meter),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: ['assets', 'meters'] })
    }
  })
}
