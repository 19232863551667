import { isObject } from "@/helpers";
import type { SortedField } from "@/types";
import { z } from "zod";
import { assertSchema } from "@/helpers/assert-schema";

type HttpMethod = 'get' | 'put' | 'post' | 'delete' | 'options' | 'trace' | 'patch' | 'head'
type AllowedUrl = URL | string

export enum Type {
  Core = 'api',
  AssetManagement = 'asset-management.services',
}

export function applyPagingToUrl(url: URL, page?: number, pageSize?: number) {
  if (page !== undefined && page > 1) {
    url.searchParams.set('page', page.toString())
  }

  if (pageSize !== undefined && pageSize > 1) {
    url.searchParams.set('limit', pageSize.toString())
  }
}

export function applyFilteringToUrl(url: URL, filter?: string, key: string = 'filter') {
  if (filter !== undefined && filter.trim().length > 0) {
    url.searchParams.set(key, filter)
  }
}

export function applySortingToUrl(url: URL, sort: SortedField[] = [], key: string = 'sort') {
  if (sort.length > 0) {
    sort.forEach(
      x => url.searchParams.append(key, x.direction === 'asc' ? x.field : `-${x.field}`)
    )
  }
}

export async function sendPaginationRequest<TSchema extends z.Schema>(url: URL, schema: TSchema) {
  const { data, meta } = await sendGetRequest(url)

  assertSchema(data, z.array(schema))
  assertSchema(meta, z.object({ page: z.number(), pageSize: z.number(), totalRecords: z.number() }))

  return { records: data, paging: meta }
}

export function buildApiUrl(type: Type, path: string = '/') {
  return new URL(
    path,
    {
      [Type.Core]: import.meta.env.VITE_API_BASE_URL,
      [Type.AssetManagement]: import.meta.env.VITE_ASSET_MANAGEMENT_API_URL,
    }[type]
  )
}

export function sendGetRequest<TData, TMeta = unknown>(url: URL, init?: RequestInit) {
  return sendRequest<TData, TMeta>({
    method: 'get',
    url,
    init
  })
}

export function sendPatchRequest<TData, TBody = unknown, TMeta = unknown>(url: URL, body?: TBody, init?: RequestInit) {
  return sendRequest<TData, TMeta>({
    method: 'patch',
    url,
    init: {
      ...(init || {}),
      body: JSON.stringify(body)
    }
  })
}

export function sendPostRequest<TData, TBody = unknown, TMeta = unknown>(url: URL, body?: TBody, init?: RequestInit) {
  return sendRequest<TData, TMeta>({
    method: 'post',
    url,
    init: {
      ...(init || {}),
      body: JSON.stringify(body)
    }
  })
}

export function sendPutRequest<TData, TBody = unknown, TMeta = unknown>(url: URL, body?: TBody, init?: RequestInit) {
  return sendRequest<TData, TMeta>({
    method: 'put',
    url,
    init: {
      ...(init || {}),
      body: JSON.stringify(body)
    }
  })
}

export function sendDeleteRequest<TData, TMeta = unknown>(url: URL, init?: RequestInit) {
  return sendRequest<TData, TMeta>({
    method: 'delete',
    url,
    init
  })
}

export default function makeApiClient(type: Type) {
  const baseUrl = {
    [Type.Core]: import.meta.env.VITE_API_BASE_URL,
    [Type.AssetManagement]: import.meta.env.VITE_ASSET_MANAGEMENT_API_URL,
  }[type]

  if (baseUrl === undefined) {
    throw new Error(`unsupported api type [${type}] provided`)
  }

  return {
    sendGetRequest<TData, TMeta = unknown>(url: AllowedUrl, init?: RequestInit) {
      return sendRequest<TData, TMeta>({
        method: 'get',
        url: new URL(url, baseUrl),
        init
      })
    },

    sendPatchRequest<TData, TBody = unknown, TMeta = unknown>(url: AllowedUrl, body?: TBody, init?: RequestInit) {
      return sendRequest<TData, TMeta>({
        method: 'patch',
        url: new URL(url, baseUrl),
        init: {
          ...(init || {}),
          body: JSON.stringify(body)
        }
      })
    },

    sendPostRequest<TData, TBody = unknown, TMeta = unknown>(url: AllowedUrl, body?: TBody, init?: RequestInit) {
      return sendRequest<TData, TMeta>({
        method: 'post',
        url: new URL(url, baseUrl),
        init: {
          ...(init || {}),
          body: JSON.stringify(body)
        }
      })
    },

    sendPutRequest<TData, TBody = unknown, TMeta = unknown>(url: AllowedUrl, body?: TBody, init?: RequestInit) {
      return sendRequest<TData, TMeta>({
        method: 'put',
        url: new URL(url, baseUrl),
        init: {
          ...(init || {}),
          body: JSON.stringify(body)
        }
      })
    },

    sendDeleteRequest<TData, TMeta = unknown>(url: AllowedUrl, init?: RequestInit) {
      return sendRequest<TData, TMeta>({
        method: 'delete',
        url: new URL(url, baseUrl),
        init
      })
    },
  }
}


export class ErrorResponse extends Error {
  readonly response: Response

  constructor(response: Response, message?: string) {
    super(message || `Received bad status code (expected 200-299, got ${response.status})`)

    this.response = response
  }
}

export class DetailedErrorResponse extends ErrorResponse {
  readonly errors: { title: string }[]

  constructor(response: Response, errors: { title: string }[]) {
    super(response, `Received bad status code (expected 200-299, got ${response.status}) with detailed errors`)

    this.errors = errors
  }
}

async function sendRequest<TData, TMeta>({ method, url, init } : { method: HttpMethod, url: URL, init?: RequestInit }): Promise<{
  data: TData,
  meta: TMeta | undefined,
  response: Response
} | { data: undefined, meta: undefined, response: Response }> {
  // Ensure we always have an object.
  init = init || {}

  // Always send the necessary credentials.
  init.credentials = "include"

  // Override the method.
  init.method = method.toUpperCase()

  // Always populate headers.
  init.headers = new Headers(init.headers)

  // Ensure we accept JSON as a response.
  init.headers.set('accept', 'application/json')

  if (!init.headers.has('content-type')) {
    init.headers.set('content-type', 'application/json')
  }

  const response = await fetch(url.toString(), init)

  // Response was successful - attempt to parse it.
  if (response.ok) {
    // If it's not a JSON response, then don't attempt to parse it.
    if (!(response.headers.get('content-type') || '').startsWith('application/json')) {
      return { data: undefined, meta: undefined, response }
    }

    const json = await response.json()

    // If we have an object & it has a data property, then return it.
    if (isObject(json) && 'data' in json) {
      return { data: json.data, meta: json.meta, response }
    }

    // Otherwise, the response was either not a property or had no [data] property - coerce it into our expected structure.
    return { data: json, meta: undefined, response }
  }

  let errors: { title: string }[]|undefined = undefined

  // Failed response received.
  try {
    // Attempt to parse as JSON.
    const json = await response.json()

    // If we have an object, and there is an [errors] property, then we can attempt to extract errors from the response.
    if (isObject(json) && 'errors' in json) {
      errors = json.errors
    }
  } catch (e: unknown) {
    // void
  }

  if (errors === undefined) {
    throw new ErrorResponse(response)
  } else {
    throw new DetailedErrorResponse(response, errors)
  }
}