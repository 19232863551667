export enum MeterTypeNumeric {
    Solar = 1,
    Generator = 2,
    Grid = 3,
    Virtual = 4,
}

export enum MeterType {
  Solar = 'SolarMeter',
  Generator = 'GeneratorMeter',
  Grid = 'GridMeter',
  Virtual = 'VirtualMeter',
}

export enum MeterBillingRegister {
  ForwardActiveEnergy = 0,
  ReverseActiveEnergy = 1,
}

export const meterBillingRegisters = [
  { value: MeterBillingRegister.ForwardActiveEnergy, text: 'ForActiveEnergy_Tot' },
  { value: MeterBillingRegister.ReverseActiveEnergy, text: 'RevActiveEnergy_Tot' },
] as const

export enum MeterStatus {
  Operational = 'Operational',
  Decommissioned = 'Decommissioned',
  OutOfService = 'OutOfService',
}

export enum DeliveryStatus {
    SetupIncomplete = 'SetupIncomplete',
    Operational = 'Operational',
    PendingDelivery = 'PendingDelivery'
}

export enum AssetType {
  Solar = 'SolarPvSystem',
  Generator = 'Generator',
}
